import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LinkButton from '../../components/shared/LinkButton';

export class F implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.F;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/prokurator.svg';
  getTitle = () => 'ZGŁOŚ PRZESTĘPSTWO DO PROKURATURY';
  getContent = () => (
    <>
      <p>
        Najłatwiej jest złożyć zawiadomienie pisemne do prokuratury. Zawiadomienie powinni złożyć w Twoim imieniu
        rodzice lub opiekunowie prawni. Porozmawiaj z nimi i zaproponuj taki rodzaj interwencji. Przygotowaliśmy 3 wzory
        zawiadomienia w zależności od tego czy masz 5 minut, 10 minut czy 20 minut na opis tego co zgłaszasz. Zgłoszenie
        wysyła się do prokuratury właściwej ze względu na miejsce zamieszkania.{' '}
      </p>
      <p>Wzory zawiadomień</p>
      <Row>
        <Col xs={3}>5-minutowy</Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/A formularz zawiadomienia 5 minut.pdf'}>PDF</LinkButton>
        </Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/A formularz zawiadomienia 5 minut.doc'}>DOC</LinkButton>
        </Col>
        <Col xs={5}>
          <LinkButton
            link={
              process.env.PUBLIC_URL + '/files/A objasnienia do formularza zawiadomienia o przestepstwie 5 minut.pdf'
            }>
            Instrukcja do formularza
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>10-minutowy</Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/B formularz zawiadomienia 10 minut.pdf'}>PDF</LinkButton>
        </Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/B formularz zawiadomienia 10 minut.doc'}>DOC</LinkButton>
        </Col>
        <Col xs={5}>
          <LinkButton
            link={
              process.env.PUBLIC_URL + '/files/B objasnienia do formularza zawiadomienia o przestepstwie 10 minut.pdf'
            }>
            Instrukcja do formularza
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>20-minutowy</Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/C formularz zawiadomienia 20 minut.pdf'}>PDF</LinkButton>
        </Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/C formularz zawiadomienia 20 minut.doc'}>DOC</LinkButton>
        </Col>
        <Col xs={5}>
          <LinkButton
            link={
              process.env.PUBLIC_URL + '/files/C objasnienia do formularza zawiadomienia o przestepstwie 20 minut.pdf'
            }>
            Instrukcja do formularza
          </LinkButton>
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col>
          <p>
            <LinkButton link='https://www.gov.pl/web/gov/zglos-przestepstwo'>Znajdź swoją prokuraturę</LinkButton>
          </p>
        </Col>
      </Row>
    </>
  );
}
