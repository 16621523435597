import { AnswerDto, InstructionsKey } from '../models/question';
import { AppState } from '../root-store';
import { processAnswer } from '../data/selectInstructionLogic';

export const getAnswers = (state: AppState): AnswerDto[] => state.surveyReducer.answers;

export const getAnswer = (state: AppState, quesitonNum: number): AnswerDto =>
  getAnswers(state).find((p) => p.questionNum === quesitonNum) as AnswerDto;

export const getInstructionKeys = (state: AppState): InstructionsKey[] => processAnswer(getAnswers(state));
