import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from './../../components/shared/LinkButton';
import { checkAnswers } from './../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class J implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.J;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'ZGŁOŚ DO FACEBOOKA';
  getContent = (answers?: AnswerDto[]) => {
    if (checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.A }, answers)) {
      return (
        <>
          <p>
            Dowiedz się jak zgłaszać szkodliwe treści na Facebooku{' '}
            <LinkButton link='https://www.facebook.com/help/reportlinks'>LINK</LinkButton>
            <br></br>
            Poproś o wsparcie rodzica lub opiekuna prawnego.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            Najlepszym sposobem zgłaszania na Facebooku treści naruszających zasady i spamu jest kliknięcie linku
            "Przekaż opinię lub Zgłoś" wyświetlanego obok danej treści. Więcej o reagowaniu na szkodliwe treści na
            Facebooku <LinkButton link='https://www.facebook.com/help/reportlinks'>LINK</LinkButton>.
          </p>
        </>
      );
    }
  };
}
