import React from 'react';

const Banner112 = () => {
  return (
    <>
      {/* <Image
        className='img maxheight'
        src={process.env.PUBLIC_URL + '/rpo/112.png'}
        alt='Jeśli zagrożone jest życie lub zdrowie zadzwoń na numer alarmowy 112'
      /> */}
      {/* <a href='tel:112' className='linkWithouDecoration'>
        <div className='banner112 bold upper marginTop1em'>
          <div className='text'>
            Jeśli zagrożone jest życie lub zdrowie <br></br>zadzwoń na numer alarmowy
          </div>
          <div className='number'>112</div>
        </div>
      </a> */}
      <a href='tel:112' className='linkWithouDecoration'>
        <div className='banner112 bold upper'>
          <div className='text'>
            Jeśli zagrożone jest życie lub zdrowie <br></br>zadzwoń na numer alarmowy
          </div>
          <div className='number'>
            <img className='img' src={process.env.PUBLIC_URL + '/rpo/112.png'} alt='112' />
          </div>
        </div>
      </a>
    </>
  );
};

export default Banner112;
