import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';

export class C implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.C;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/Porozmawiaj.svg';
  getTitle = () => 'Porozmawiaj ze swoim dzieckiem';
  getContent = () => (
    <>
      <p>
        Twoje dziecko padło ofiarą prześladowania w internecie, otrzymuje niepokojące Cię wiadomości, a może obawiasz
        się, że zobaczy szkodliwe treści? Niezależnie od powodu - porozmawiaj z dzieckiem o tym, co się zdarzyło.
      </p>
      <ul>
        <li>
          Bądź uważnym dorosłym. Zwróć uwagę na emocje i potrzeby dziecka. Pamiętaj, że Twoje dziecko głęboko przeżywa
          swoją codzienność, nawet jeżeli pozornie nie daje tego po sobie poznać. Potrzebuje przy tym Twojej obecności i
          życzliwej rozmowy.
        </li>
        <li>
          Postaraj się towarzyszyć dziecku w trudnym dla niego momencie. Wysłuchaj tego, co ma do powiedzenia. Bądź też
          obecny, kiedy dziecko milczy. W momentach, kiedy nie umie albo nie jest gotowe podzielić się swoją historią,
          wspieraj je. Kiedy zdecyduje się porozmawiać z Tobą o swoim problemem, staraj się reagować zrozumieniem i
          akceptacją.
        </li>
        <li>
          Zwróć uwagę na odpowiednie warunki rozmowy. Znajdź czas, żeby zatrzymać się na chwilę i z trybu działania
          przełączyć się na tryb uważnej obecności. Na chwilę przestań myśleć o wszystkich obowiązkach, które czekają.
          Nie pospieszaj rozmowy, nie przerywaj opowieści, nie rób jednocześnie innych rzeczy. Dobrze jest, jeśli
          rozmowa odbywa się w miejscu przyjaznym dziecku, w którym czuje się bezpiecznie.
        </li>
        <li>
          Rozmowa to dialog, w którym jest miejsce na odmienne zdanie dziecka. Doceniaj te chwile, kiedy przedstawia
          własną opinię i potrafi jej bronić. Wspieraj je w wyrażaniu własnego zdania.
        </li>
        <li>Powstrzymaj się od krytyki, oceniania i porównywania z innymi.</li>
        <li>Mów o tym, co jest dla Ciebie ważne jako rodzica.</li>
        <li>
          Pomóż dziecku zabezpieczyć dowody. Uczul je, aby nie kasowało historii rozmów, wiadomości oraz innych
          materiałów, które mogą potwierdzić to, co się zdarzyło. Pamiętaj, aby zapisać zrzuty ekranu.
        </li>
        <li>
          Jeśli masz wątpliwości dotyczące rozmowy z dzieckiem lub sytuacji, w której się znalazło możesz zasięgnąć
          porady specjalisty dzwoniąc pod bezpłatny numer Telefonu dla Rodziców i Profesjonalistów 800 100 100.
        </li>
        <li>Podejmij interwencję, zgłoś to!</li>
      </ul>
      <p>
        Dowiedz się więcej: <LinkButton link='http://uwaznirodzice.pl'> uwaznirodzice.pl</LinkButton>
      </p>
    </>
  );
}
