import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';

export class R implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.R;
  isEnable = () => false;
  getIconSrc = () => '/rpo/svg/policeman.svg';
  getTitle = () => 'ZGŁOŚ PRZESTĘPSTWO NA POLICJĘ';
  getContent = () => (
    <>
      <p></p>
    </>
  );
}
