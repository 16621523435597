import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { checkAnswers } from '../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class K implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.K;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'ZGŁOŚ DO YouTube';
  getContent = (answers?: AnswerDto[]) => (
    <>
      <p>
        Dowiedź się jak zgłaszać szkodliwe treści na YouTube{' '}
        <LinkButton link='https://support.google.com/youtube/answer/2802027?hl=pl&ref_topic=9387085' />
        {checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.A }, answers) && (
          <>
            <br></br>
            Poproś o wsparcie rodzica lub opiekuna prawnego.
          </>
        )}
      </p>
    </>
  );
}
