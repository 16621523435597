import { AnswerDto, InstructionsKey } from '../models/question';

export interface SurveyState {
  answers: AnswerDto[];
  instructionKeys: InstructionsKey[];
}

export const SET_ANSWER = '@survey/SET_ANSWER';

interface SetAnswer {
  type: typeof SET_ANSWER;
  answer: AnswerDto;
  toAdd: boolean;
}

export type SurveyActions = SetAnswer | SetAnswer;
