import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RoutePaths } from '../RoutePaths';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} xs={12}>
            <Row>
              <Col md={{ span: 2 }} xs={6} className='fotterMenu'>
                <Link to={RoutePaths.about} className='upper link'>
                  O nas
                </Link>
              </Col>
              <Col md={{ span: 8 }} xs={6} className='fotterMenu justifyCenter'>
                <Link to={RoutePaths.privacyPolicy} className='upper link'>
                  Politka prywatności
                </Link>
              </Col>
              {/* <Col md={{ span: 4 }} xs={6} className='fotterMenu'>
                <Link to={RoutePaths.regulations} className='upper link'>
                  Regulamin serwisu
                </Link>
              </Col> */}
              <Col md={{ span: 2 }} xs={6} className='fotterMenu'>
                <a
                  href={process.env.PUBLIC_URL + '/files/PolitykaOchronyDanychFDDS_2019_final.docx'}
                  className='upper link'>
                  Nota prawna
                </a>
              </Col>
            </Row>
            <Row className={'marginTop3em partners'}>
              <Col xs={12}>
                <Row>
                  <Col md='2' xs={12} className='fotterDesc withoutBold'>
                    Organizatorzy:
                  </Col>
                  <Col md='3' xs={6} className='itemsToCenter itemsToUp '>
                    <a href='#' className='linkWithouDecoration' target='_blank'>
                      <img
                        loading='lazy'
                        className='imgFooterLogo imgSmallWidth rpoLogo'
                        src={process.env.PUBLIC_URL + '/rpo/rpo_w_opt.png'}
                        alt='Rzecznik praw obywatelskich'></img>
                    </a>
                  </Col>
                  <Col md='3' xs={6} className='itemsToCenter itemsToUp '>
                    <a href='#' className='linkWithouDecoration' target='_blank'>
                      <img
                        loading='lazy'
                        className='imgFooterLogo imgSmallWidth'
                        src={process.env.PUBLIC_URL + '/rpo/dajemydzieciomsile_poziom_kontra_RGB (1)_opt.png'}
                        alt='Fundacja dajemy dzieciom siłę'></img>
                    </a>
                  </Col>
                </Row>
                <Row className={'marginTop2em'}>
                  <Col md='2' xs={12} className='fotterDesc withoutBold'>
                    Partnerzy:
                  </Col>
                  <Col md='2' xs={4} className='itemsToCenter footerLogo imgSmallWidth itemsToUp '>
                    <a href='#' className='linkWithouDecoration' target='_blank'>
                      <img
                        loading='lazy'
                        className='imgFooterLogo imgSmallWidth'
                        src={process.env.PUBLIC_URL + '/rpo/ddb.png'}></img>
                    </a>
                  </Col>
                  <Col md='3' xs={7} className='itemsToCenter footerLogo itemsToUp '>
                    <a href='#' className='linkWithouDecoration' target='_blank'>
                      <img
                        loading='lazy'
                        className='imgFooterLogo imgMaxWidth'
                        src={process.env.PUBLIC_URL + '/rpo/FACEBOOK_logo_White_RGB.svg'}></img>
                    </a>
                  </Col>
                  <Col md='3' xs={7} className='itemsToCenter footerLogo itemsToUp '>
                    <a href='#' className='linkWithouDecoration' target='_blank'>
                      <img
                        loading='lazy'
                        className='imgFooterLogo imgMaxWidth'
                        src={process.env.PUBLIC_URL + '/rpo/logo H+K horizontal (magenta RGB) white_opt.png'}></img>
                    </a>
                  </Col>
                </Row>

                <Row className={'marginTop2em'}>
                  <Col md='2' xs={12} className='fotterDesc withoutBold'>
                    Realizacja:
                  </Col>
                  <Col md='4' xs={7} className='itemsToCenter itemsToUp '>
                    <a href='https://itmagination.com' className='linkWithouDecoration' target='_blank'>
                      <img
                        loading='lazy'
                        className='imgFooterLogo imgSmallWidth'
                        src={process.env.PUBLIC_URL + '/rpo/itm_w.png'}
                        alt='ITMAGINATION'></img>
                    </a>
                  </Col>
                </Row>
                <Row className={'marginTop2em'}>
                  <Col md={{ span: 4, offset: 4 }} xs={12} className='textAlignCenter itmLogo'>
                    <a href='https://itmagination.com' className='linkWithouDecoration ' target='_blank'>
                      ITMAGINATION 2020
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
