import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../root-store';
import { QuestionOptionDto, AnswerDto } from '../../models/question';
import { getQuestionOptionData } from '../../data/questionsData';
import { Button, Row, Col } from 'react-bootstrap';
import { setAnswer } from './../../store/actions';
import { getAnswer } from '../../store/selectors';

interface OwnProps {
  questionNum: number;
  answerKey: string;
}

interface StateProps {
  questionOption: QuestionOptionDto;
  answer: AnswerDto;
}

const actions = {
  setAnswer,
};

type DispatchProps = typeof actions;

type Props = StateProps & OwnProps & DispatchProps;

const QuestionOption: React.FC<Props> = ({ questionOption, questionNum, setAnswer, answer }) => {
  const [collapse, setCollapseToogle] = useState(false);

  const getIdKey = () => {
    return `collapseExample_${questionNum}_opt_${questionOption.key}`;
  };

  const isChecked = (): boolean => {
    return !!answer && answer.optKey === questionOption.key;
  };

  return (
    <Row className={`questionOption ${isChecked() || collapse ? 'checked' : ''}`}>
      <Col xs={12}>
        <Row>
          <div className='customRadio'>
            <Button onClick={() => setAnswer(questionNum, questionOption.key, !isChecked())}>
              <div className={`circle ${isChecked() ? 'checked' : ''}`}></div>
            </Button>
          </div>
          <p className='fillRow pad1 bold'>{questionOption.text}</p>
          {questionOption.desc && (
            <button
              type='button'
              className='descCollapse pad1 withoutShaddow selfCenter'
              onClick={() => setCollapseToogle(!collapse)}>
              Co to znaczy?
            </button>
          )}
        </Row>
      </Col>
      {questionOption.desc && (
        <Col xs={12} className={`collapse padBottom ${collapse && 'show'}`} id={getIdKey()}>
          <hr></hr>
          <Col xs={12} md={{ span: 12 }}>
            {questionOption.desc}
          </Col>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => ({
  questionOption: getQuestionOptionData(ownProps.questionNum, ownProps.answerKey),
  answer: getAnswer(state, ownProps.questionNum),
});

export default connect(mapStateToProps, actions)(QuestionOption);
