import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

interface Props {
  src: string;
  className?: string;
  alt: string;
}

const ImgBox: React.FC<Props> = (props) => {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <img
        loading='lazy'
        alt={props.alt}
        src={props.src}
        onClick={() => setToggler(!toggler)}
        className={props.className}></img>
      <FsLightbox toggler={toggler} sources={[props.src]} />
    </>
  );
};

export default ImgBox;
