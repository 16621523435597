import React from 'react';
import './App.scss';
import Survey from './components/survey/Survey';
import Footer from './components/Footer';
import About from './components/About';
import Header from './components/Header';
import LegalNotice from './components/LegalNotice';

import Regulations from './components/Regulations';
import PrivacyPolicy from './components/PrivacyPolicy';
import { Provider } from 'react-redux';
import { store } from './root-store';
import { Router, Switch, Route, HashRouter } from 'react-router-dom';
import { RoutePaths } from './RoutePaths';
import CookieConsent from 'react-cookie-consent';
import LinkButton from './components/shared/LinkButton';
import { GoogleWrapperWithRouter } from './GoogleWrapper';
import { createBrowserHistory } from 'history';
import { Container } from 'react-bootstrap';

const App = (props: any) => {
  const history = createBrowserHistory();
  return (
    <HashRouter>
      <Provider store={store}>
        <GoogleWrapperWithRouter {...props}>
          <header className='container-fluid'>
            <Header />
          </header>
          <main className='container'>
            <Switch>
              <Route path={RoutePaths.privacyPolicy}>
                <PrivacyPolicy />
              </Route>
              <Route path={RoutePaths.regulations}>
                <Regulations />
              </Route>
              <Route path={RoutePaths.legalNotice}>
                <LegalNotice />
              </Route>
              <Route path={RoutePaths.about}>
                <About />
              </Route>
              <Route path={RoutePaths.survey}>
                <Survey />
              </Route>
            </Switch>
          </main>
          <Footer />
        </GoogleWrapperWithRouter>
      </Provider>
      <CookieConsent buttonText='Akceptuję'>
        <p>Ta strona używa plików cookies.</p>
        <p>
          Więcej informacji na ten temat znajdziesz{' '}
          <LinkButton link='https://fdds.pl/polityka-prywatnosci-serwisow-internetowych-fundacji-dzieci-niczyje/'>
            tutaj
          </LinkButton>
          .
        </p>
        <p>
          Informacja o plikach cookies: Informujemy, iż w celu optymalizacji treści dostępnych w naszym serwisie oraz
          dostosowania ich do Państwa indywidualnych potrzeb, korzystamy z informacji zapisanych za pomocą plików
          cookies na urządzeniach końcowych użytkowników. Pliki cookies użytkownik może kontrolować za pomocą ustawień
          swojej przeglądarki internetowej. Dalsze korzystanie z naszego serwisu bez zmiany ustawień przeglądarki
          oznacza, iż użytkownik akceptuje stosowanie plików cookies. Więcej informacji znajduje się na{' '}
          <LinkButton link='https://fdds.pl/polityka-prywatnosci-serwisow-internetowych-fundacji-dajemy-dzieciom-sile/'>
            tej stronie
          </LinkButton>
          .
        </p>
      </CookieConsent>
    </HashRouter>
  );
};

export default App;
