import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { checkAnswers } from '../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class N implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.N;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'ZGŁOŚ DO Instagrama';

  getContent = (answers?: AnswerDto[]) => (
    <>
      <p>
        Dowiedź się jak zgłaszać szkodliwe treści na Instagramie{' '}
        <LinkButton link='https://www.facebook.com/help/instagram/2922067214679225/?helpref=hc_fnav' />
        {checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.A }, answers) && (
          <>
            <br></br>
            Poproś o wsparcie rodzica lub opiekuna prawnego.
          </>
        )}
      </p>
    </>
  );
}
