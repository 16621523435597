import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../root-store';
import { Row, OverlayTrigger, Tooltip, Col } from 'react-bootstrap';
import { getQuestionsData } from '../../data/questionsData';
import QuestionOption from './QuestionOption';
import { QuestionDto } from '../../models/question';
import { ScrollFlowHelper } from '../../data/scrollFlowHelper';

interface OwnProps {
  questionNum: number;
}

interface StateProps {
  question: QuestionDto;
}

const actions = {};

type DispatchProps = typeof actions;

type Props = StateProps & OwnProps & DispatchProps;

const Question: React.FC<Props> = ({ question }) => {
  const tooltip = (text: string) => {
    const renderTooltip = (props: any) => {
      return (
        <Tooltip id={`tooltip-top`} className='customTooltip' {...props}>
          {text}
        </Tooltip>
      );
    };

    return (
      <OverlayTrigger placement={'top'} trigger={['hover', 'focus', 'click']} overlay={renderTooltip}>
        <i className='fa fa-info-circle'></i>
      </OverlayTrigger>
    );
  };

  return (
    <Row className='spaceQuestions new2Css' id={ScrollFlowHelper.getQuestionId(question.num)}>
      <Col xs={12}>
        <p className='bold headerFont'>
          {question.num}. {question.text} {question.info && tooltip(question.info)}
        </p>
        {question.options.map((p) => {
          return (
            <QuestionOption
              key={`questionNum_${question.num}_opt_${p.key}`}
              answerKey={p.key}
              questionNum={question.num}
            />
          );
        })}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    question: getQuestionsData(ownProps.questionNum),
  };
};

export default connect(mapStateToProps, actions)(Question);
