export interface QuestionDto {
  num: number;
  text: string;
  info?: string;
  options: QuestionOptionDto[];
}

export interface QuestionOptionDto {
  key: string;
  text: string;
  desc?: string;
}

export interface AnswerDto {
  questionNum: number;
  optKey: string;
}

export interface Instruction {
  key: keyof InstructionsKey;
}

export enum InstructionsKey {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
}
