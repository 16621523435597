import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LinkButton from './shared/LinkButton';

const PrivacyPolicy = () => {
  return (
    <>
      <Row className='textJustify'>
        <Col xs={{ span: 10, offset: 1 }}>
          <h3>Polityka prywatności serwisów internetowych Fundacji Dajemy Dzieciom Siłę</h3>
          <p>
            Fundacja Dajemy Dzieciom Siłę, prowadząc serwisy www ma na celu przede wszystkim edukację dzieci i dorosłych
            w zakresie przeciwdziałania i reagowania na krzywdzenie dzieci. Fundacja Dajemy Dzieciom Siłę szczególną
            uwagę zwraca na ochronę prywatności użytkowników swoich serwisów, zwłaszcza użytkowników małoletnich.
            Niniejsza Polityka ma na celu wyjaśnienie, jakie dane użytkowników serwisów i w jakim celu przetwarza
            Fundacja.
          </p>
          <p>
            <i>Dane osobowe użytkowników:</i>
          </p>
          <ol>
            <li>
              Prowadząc serwisy internetowe Fundacja gromadzi następujące dane osobowe użytkowników tych serwisów:
              <ol>
                <li>
                  dane osobowe niezbędne do procesu rekrutacji na szkolenia, konferencje, inne wydarzenia edukacyjne – w
                  zakresie i w celu określonym w formularzu rejestracyjnym wypełnianym przez użytkownika;
                </li>
                <li>
                  dane osobowe niezbędne do skorzystania z oferty edukacyjnej dla profesjonalistów i rodziców dzieci
                  (e-learning i in.) – w zakresie i w celu określonym w formularzu rejestracyjnym lub w formularzu
                  zamówienia wydawnictwa, wypełnianym przez użytkownika;
                </li>
                <li>
                  dane osobowe niezbędne do skorzystania z oferty edukacyjnej dla dzieci – w zakresie i w celu
                  określonym w formularzu wypełnianym przez rodzica/opiekuna prawnego małoletniego użytkownika;
                </li>
                <li>
                  dane osób kontaktujących się z Fundacją telefonicznie, poprzez e-mail – w zakresie i w celu niezbędnym
                  do odpowiedzi na pytanie, w związku z którym użytkownik kontaktuje się z Fundacją.
                </li>
                <li>
                  dane osobowe subskrybentów newsletterów z serwisów Fundacji – w zakresie i w celu określonym w
                  formularzu zamówienia newslettera.
                </li>
                <li>
                  dane osobowe osób wspierających Fundację (w szczególności: darczyńców, członków Klubu Przyjaciół
                  Dzieci) – w zakresie i w celu określonym w formularzach, wypełnianych przy deklarowaniu przez
                  użytkownika udzielenia Fundacji odpowiedniego wsparcia.
                </li>
              </ol>
            </li>
            <li>
              Administratorem wyżej wymienionych danych osobowych jest Fundacja Dajemy Dzieciom Siłę z siedzibą w
              Warszawie przy ul. Walecznych 59.
            </li>
            <li>
              Użytkownik może w każdej chwili wycofać zgodę na przetwarzanie jego danych osobowych, wysyłając e-mail na
              odpowiedni adres e-mail (podany w miejscu pozyskiwania danych osobowych użytkownika, w razie braku takiego
              wskazania e-mail z wycofaniem zgody należy wysłać na adres ogólny Fundacji:{' '}
              <a href='mailto:biuro@fdds.pl'>biuro@fdds.pl</a> z zaznaczeniem w tytule: „Wycofanie zgody na
              przetwarzanie danych osobowych”).
            </li>
            <li>
              W razie wycofania zgody na przetwarzanie danych osobowych świadczenie niektórych usług z serwisów Fundacji
              może okazać się niemożliwe.
            </li>
            <li>
              Użytkownik może także zrezygnować z otrzymywania newslettera – poprzez kliknięcie w odpowiedni link
              umieszczony na końcu każdego numeru newslettera.
            </li>
          </ol>

          <p>
            <i>Pliki cookies</i>
          </p>
          <ol>
            <li>
              Serwisy Fundacji wykorzystują tzw. pliki cookies. Fundacja wykorzystuje te pliki w celu:
              <ol>
                <li>
                  dostosowania zawartości serwisów do preferencji użytkownika oraz optymalizacji korzystania z nich;
                </li>
                <li>tworzenia statystyk, dotyczących sposobu korzystania przez Użytkowników z serwisów.</li>
              </ol>
            </li>
            <li>
              W wielu przypadkach przeglądarka internetowa, z której korzysta użytkownik, domyślnie dopuszcza
              przechowywanie plików cookies w urządzeniu końcowym użytkownika.
            </li>
            <li>
              Użytkownicy serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies, w
              szczególności w taki sposób, aby:
              <ol>
                <li>zablokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej lub</li>
                <li>informować o ich każdorazowym zamieszczeniu w urządzeniu użytkownika serwisu.</li>
              </ol>
            </li>
            <li>
              Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach
              przeglądarki internetowej.
            </li>
            <li>
              Po zablokowaniu przez użytkownika instalacji plików cookies w ustawieniach przeglądarki, niektóre funkcje
              serwisów Fundacji mogą nie działać.
            </li>
          </ol>
          <p>
            <i>Inne postanowienia</i>
          </p>
          <ol>
            <li>
              Podczas korzystania z serwisów Fundacji automatycznie zapisywane są tzw. logi systemowe – anonimowe
              informacje takie jak czas wizyty użytkownika w serwisie, adres IP użytkownika, adres URL, rodzaj
              przeglądarki, dane o pobieranych plikach itp.
            </li>
            <li>Zebrane logi przechowywane są przez czas nieokreślony w celu statystycznym/analizy statystycznej</li>
            <li>
              Serwisy Fundacji korzystają z Google Analytics w celu pozyskania anonimowych danych statystycznych (liczba
              odwiedzin, kraj logowania, rodzaj przeglądarki, czas wizyty itp.). Szczegóły związane z korzystaniem przez
              serwisy z tej usługi można znaleźć w{' '}
              <LinkButton link='http://www.google.pl/intl/pl/analytics/privacyoverview.html'>
                polityce prywatności Google Analytics
              </LinkButton>
              .
            </li>
            <li>
              Serwisy Fundacji korzystają z wtyczki społecznościowej Facebook.com. Informacje w zakresie ochrony
              prywatności związane z korzystaniem z wtyczki Facebook.com dostępne są w{' '}
              <LinkButton link='https://pl-pl.facebook.com/about/privacy/'>
                zasadach prywatności serwisu Facebook
              </LinkButton>
              .
            </li>
            <li>
              W serwisach Fundacji znajdują się odesłania do serwisu YouTube i do subskrypcji kanału Fundacji w tym
              serwisie. Informacje w zakresie ochrony prywatności związane z korzystaniem z YouTube dostępne są w{' '}
              <LinkButton link='https://www.google.com/intl/pl/policies/privacy/'>
                zasadach prywatności Google
              </LinkButton>
              .
            </li>
            <li>
              Serwisy FDN zawierają odnośniki do innych stron internetowych. Fundacja nie ponosi odpowiedzialności za
              zasady zachowania prywatności obowiązujące na innych stronach.
            </li>
            <li>
              Niniejsza Polityka prywatności może podlegać zmianom. O wszystkich zmianach użytkownicy będą informowani
              poprzez opublikowanie zmienionej polityki prywatności na tej podstronie.
            </li>
          </ol>
          <p>
            NOTA PRAWNA: W niniejszej Polityce wykorzystano fragmenty wzoru polityki cookies, do którego prawa autorskie
            przysługują IAB Polska. Źródło:{' '}
            <LinkButton link='http://wszystkoociasteczkach.pl'>wszystkoociasteczkach.pl</LinkButton>.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
