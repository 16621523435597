import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import Cap from './../../components/shared/Cap';
import LinkButton from '../../components/shared/LinkButton';

export class A implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.A;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/Policeman.svg';
  getTitle = () => 'ZGŁOŚ PRZESTĘPSTWO NA POLICJĘ';
  getContent = () => (
    <>
      <p>
        <Cap>telefonicznie</Cap> Jeśli zagrożone jest życie lub zdrowie – Twoje lub osoby, której pomagasz – zadzwoń pod
        numer alarmowy 112. Możesz zostać poproszony o zgłoszenie się do najbliższego komisariatu, aby złożyć
        zawiadomienie osobiście.
      </p>
      <p>
        <Cap>Mail</Cap> Możesz też zgłosić przestępstwo mailem na adres mailowy komendy (
        <LinkButton link='https://www.gov.pl/web/kgpsp/komendy-wojewodzkie'>
          Strony internetowe komend wojewódzkich
        </LinkButton>
        ). Nie zawsze otrzymasz odpowiedź z komendy, jednak Twoje zgłoszenie zostanie przyjęte i będzie rozpatrzone.
      </p>
      <p>
        Napisz na przykład:
      </p>
      <ul>
        <li> Natrafiłem w internecie na film, w którym mężczyzna bije dziecko (link). Proszę o zajęcie się tym </li>
        <li> Kolega napisał, że myśli o samobójstwie (link, ewentualnie dane kolegi i adres)</li>
        <li> Nieznajoma osoba grozi mi śmiercią w komentarzu pod moim postem (link)</li>
      </ul>
      <p>
        <Cap>osobiście</Cap> Możesz też zgłosić się osobiście do najbliższej jednostki policji. Otrzymasz wtedy pisemne
        potwierdzenie zawiadomienia o popełnieniu przestępstwa. W przypadku takiego rozwiązania, musisz liczyć się z
        koniecznością czekania w kolejce.
      </p>
    </>
  );
}
