import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';

export class G implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.G;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/Porozmawiaj.svg';
  getTitle = () => 'Porozmawiaj z rodzicem/opiekunem lub zaufanym dorosłym';
  getContent = () => (
    <>
      <p>
        W takich sytuacjach mogą Ci towarzyszyć różne negatywne emocje takie jak wstyd, strach, złość i smutek. To
        naturalna reakcja, na to, co się dzieje. Nie musisz pozostawać z tym sam/a. W takich chwilach dobrze jest
        poszukać wsparcia osób dorosłych, którym ufasz. Dzieląc się swoimi przeżyciami z kimś bliskim, możesz poczuć się
        lepiej. Rodzice lub opiekunowie mają możliwość podjęcia różnych działań, które zapewnią Ci bezpieczeństwo.
      </p>
      <p>
        Jak rozmawiać z zaufanym dorosłym?
        <ol>
          <li>
            Postaraj się znaleźć dobry moment, kiedy możesz zostać sam na sam z osobą, której chcesz o tym powiedzieć.
            Ważne jest, aby nikt Wam nie przeszkadzał. Jeśli naturalnie nie ma takiej możliwości, poproś zaufaną osobę
            dorosłą o rozmowę w cztery oczy.
          </li>
          <li>
            Postaraj się mówić o konkretach. Opisz to, co się wydarzyło. Jeśli trudno Ci wytłumaczyć sytuację, o której
            chcesz rozmawiać, możesz pokazać zaufanej osobie dorosłej treści lub rozmowy online, których ona dotyczy.
          </li>
          <li>
            Nie oceniaj rozmówcy ani osób, o których rozmawiacie. Najważniejsze jest to, aby dorosły dowiedział się o
            tym, co się zdarzyło, dzięki czemu będzie mógł udzielić Ci wsparcia i pomocy.
          </li>
          <li>
            Spróbuj nazwać to, co czujesz. Nie bój się emocji, które mogą się pojawiać podczas opowiadania o trudnej dla
            Ciebie sytuacji.
          </li>
          <li>
            Wysłuchaj tego, co ma do powiedzenia zaufana osoba dorosła. Postaraj się współpracować w trakcie zbierania
            dowodów oraz zgłaszania sprawy.
          </li>
          <li>
            Jeśli masz wątpliwości dotyczące podjęcia rozmowy z zaufaną osobą dorosłą możesz o tym anonimowo porozmawiać
            dzwoniąc pod bezpłatny, całodobowy numer Telefonu Zaufania dla Dzieci i Młodzieży{' '}
            <LinkButton link='tel:116111'>116 111</LinkButton>.
          </li>
        </ol>
      </p>
    </>
  );
}
