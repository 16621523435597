import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../root-store';
import { Col } from 'react-bootstrap';
import { getAnswers } from '../../store/selectors';
import { InstructionsKey, AnswerDto } from '../../models/question';
import Instruction from './Instruction';
import { processAnswer } from '../../data/selectInstructionLogic';
import { ScrollFlowHelper } from '../../data/scrollFlowHelper';

interface OwnProps {}

interface StateProps {
  instructionKeys: InstructionsKey[];
  answers: AnswerDto[];
}

const actions = {};

type DispatchProps = typeof actions;

type Props = StateProps & OwnProps & DispatchProps;

const Instructions: React.FC<Props> = ({ instructionKeys }) => {
  if (!instructionKeys || instructionKeys.length === 0) {
    return null;
  }
  return (
    <Col className='spaceQuestions' md={{ span: 8, offset: 2 }} id={ScrollFlowHelper.instructionAnchor}>
      <p className='bold headerFont'>Poniżej wskazujemy Ci, jak możesz zareagować.</p>
      {instructionKeys.map((p) => (
        <Instruction key={`instructionKey_parent_${p.toString()}`} instructionKey={p} />
      ))}
    </Col>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const answers = getAnswers(state);
  return {
    answers: answers,
    instructionKeys: processAnswer(answers),
  };
};

export default connect(mapStateToProps, actions)(Instructions);
