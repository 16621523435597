import { InstructionsKey } from '../models/question';
import { InstructionComponent } from '../models/instructions';
import { A } from './instructions/A';
import { B } from './instructions/B';
import { C } from './instructions/C';
import { D } from './instructions/D';
import { E } from './instructions/E';
import { F } from './instructions/F';
import { G } from './instructions/G';
import { I } from './instructions/I';
import { J } from './instructions/J';
import { K } from './instructions/K';
import { L } from './instructions/L';
import { M } from './instructions/M';
import { N } from './instructions/N';
import { O } from './instructions/O';
import { P } from './instructions/P';
import { Q } from './instructions/Q';
import { R } from './instructions/R';

export const getInstructionComponent = (key: InstructionsKey): InstructionComponent => {
  if (instructionComponentList) {
    const found = instructionComponentList.find((p) => p.getInstructionKey() === key);
    if (found) {
      return found;
    }
  }

  throw new Error('Not found component for Instruction');
};

export const instructionComponentList = [
  new A(),
  new B(),
  new C(),
  new D(),
  new E(),
  new F(),
  new G(),
  new I(),
  new J(),
  new K(),
  new L(),
  new M(),
  new N(),
  new O(),
  new P(),
  new Q(),
  new R(),
] as InstructionComponent[];
