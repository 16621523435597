import React from 'react';
import Description from './Description';
import Questions from './Questions';
import { Row } from 'react-bootstrap';
import Instructions from './Instructions';
const Survey = () => {
  return (
    <>
      <Description />
      <Row>
        <Questions />
        <Instructions />
      </Row>
    </>
  );
};

export default Survey;
