import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { RoutePaths } from '../RoutePaths';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <Row className='imgHeaderRow '>
        <Col className='imgHeaderCol logo ' xs={12}>
          <Link to={RoutePaths.survey} className='linkWithouDecoration'>
            {/* Zgłoś.t<span className='red'>o</span> */}
            <Image
              loading={'lazy'}
              className='img marginTop05em imgMainLogo'
              alt='Zgłoś to'
              src={process.env.PUBLIC_URL + '/rpo/svg/logo-zglosto-black.svg'}
            />
          </Link>
        </Col>
      </Row>

      <hr className='headerHr'></hr>
    </>
  );
};

export default Header;
