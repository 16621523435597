import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../root-store';
import { Row, Col } from 'react-bootstrap';
import { InstructionsKey, AnswerDto } from '../../models/question';
import { getInstructionComponent } from '../../data/instructionsData';
import { InstructionComponent } from '../../models/instructions';
import ReactGA from 'react-ga';
import { getAnswers } from '../../store/selectors';

interface OwnProps {
  instructionKey: InstructionsKey;
}

interface StateProps {
  selectedComponent: InstructionComponent;
  answers: AnswerDto[];
}

const actions = {};

type DispatchProps = typeof actions;

type Props = StateProps & OwnProps & DispatchProps;

const Instruction: React.FC<Props> = ({ selectedComponent, answers }) => {
  const [active, setToogle] = useState(false);

  if (!selectedComponent || !selectedComponent.isEnable()) {
    return null;
  }

  const getIdKey = () => {
    return `collapseExampleInstruction_${selectedComponent.getInstructionKey().toString()}`;
  };

  const showMore = () => {
    setToogle(!active);
    ReactGA.event({
      category: 'Show more info',
      action: selectedComponent.getTitle(),
    });
  };

  return (
    <Row className='instructionOption new2Css marginBottom05'>
      <Col xs={12}>
        <Row className='instructionHeader'>
          <div className='instructionIcon'>
            <img src={process.env.PUBLIC_URL + selectedComponent.getIconSrc()} alt='Ikona'></img>
          </div>
          <p className='fillRow title'>{selectedComponent.getTitle()}</p>

          <button
            type='button'
            className='descCollapse pad1 withoutShaddow selfCenter'
            onClick={() => showMore()}
            data-target={`#${getIdKey()}`}>
            {active && 'Pokaż mniej'}
            {!active && 'Pokaż więcej'}
          </button>
        </Row>
      </Col>
      <Col xs={12} className={`collapse padBottom content ${active && 'show'}`} id={getIdKey()}>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <div className='wrapper'>{selectedComponent.getContent(answers)}</div>
        </Col>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    selectedComponent: getInstructionComponent(ownProps.instructionKey),
    answers: getAnswers(state),
  };
};

export default connect(mapStateToProps, actions)(Instruction);
