import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { checkAnswers } from '../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class I implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.I;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'Zgłoś do Dyżurnet.pl';
  getContent = (answers?: AnswerDto[]) => (
    <>
      {checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.A }, answers) && (
        <p>Poproś o wsparcie rodzica lub opiekuna prawnego. </p>
      )}
      <p>
        <LinkButton link='https://dyzurnet.pl/formularz/?pl'>Dyżurnet.pl</LinkButton>
      </p>
      <p>
        Dyżurnet.pl to zespół ekspertów Naukowej i Akademickiej Sieci Komputerowej, działający jako punkt kontaktowy do
        zgłaszania nielegalnych treści w Internecie, szczególnie związanych z seksualnym wykorzystywaniem dzieci.
      </p>
    </>
  );
}
