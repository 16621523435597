import { SurveyState, SurveyActions, SET_ANSWER } from './types';
import { InstructionsKey } from '../models/question';

const initialState: SurveyState = {
  answers: [
    // { questionNum: QuestionsEnum.q1, optKey: Options.A },
    // { questionNum: QuestionsEnum.q2, optKey: Options.A },
    // { questionNum: QuestionsEnum.q3, optKey: Options.A },
    // { questionNum: QuestionsEnum.q4, optKey: Options.C },
  ],
  instructionKeys: [InstructionsKey.A],
};

export function surveyReducer(state = initialState, action: SurveyActions): SurveyState {
  switch (action.type) {
    case SET_ANSWER:
      let newAnswers = state.answers.filter((p) => p.questionNum !== action.answer.questionNum);

      if (action.toAdd) {
        newAnswers = [...newAnswers, action.answer];
      }

      return {
        ...state,
        answers: newAnswers,
      };
    default:
      return state;
  }
}
