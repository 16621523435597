export class ScrollFlowHelper {
  static prefix = 'question_';
  static instructionAnchor = 'instructionsSection';
  static getQuestionId = (num: number): string => ScrollFlowHelper.prefix + num;

  static goToNextQuestion = (num: number): void => {
    const nextQuestion = ScrollFlowHelper.getNextQuestion(num);
    const id = ScrollFlowHelper.getNextStepId(nextQuestion);
    const ele = document.getElementById(id.replace('#', ''));
    if (ele) {
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }
  };

  private static getNextStepId = (num: number | null) => {
    if (num !== null) {
      return ScrollFlowHelper.getQuestionId(num);
    }
    return ScrollFlowHelper.instructionAnchor;
  };

  private static getNextQuestion = (num: number): number | null => {
    const n = Number(num);
    if (Number.isInteger(n)) {
      if (n < 4) {
        return n + 1;
      }
    }
    return null;
  };
}
