import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../root-store';
import Question from './Question';
import { questionsData } from '../../data/questionsData';
import { Col } from 'react-bootstrap';
interface OwnProps {}

interface StateProps {}

const actions = {};

type DispatchProps = typeof actions;

type Props = StateProps & OwnProps & DispatchProps;

const Questions: React.FC<Props> = () => {
  return (
    <Col md={{ span: 8, offset: 2 }}>
      {questionsData.map((p) => {
        return <Question key={`questionNum_${p.num}`} questionNum={p.num} />;
      })}
    </Col>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {} as StateProps;
};

export default connect(mapStateToProps, actions)(Questions);
