import React from 'react';
import { Col, Row } from 'react-bootstrap';

const LegalNotice = () => {
  return (
    <>
      <Row>
        <Col xs={12}>LegalNotice</Col>
      </Row>
    </>
  );
};

export default LegalNotice;
