import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import Cap from '../../components/shared/Cap';
import LinkButton from '../../components/shared/LinkButton';

export class B implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.B;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/Policeman.svg';
  getTitle = () => 'ZGŁOŚ PRZESTĘPSTWO NA POLICJĘ';
  getContent = () => (
    <>
      <p>
        <Cap>telefonicznie</Cap> Jeśli zagrożone jest życie lub zdrowie – Twoje lub osoby której pomagasz – zadzwoń pod
        numer alarmowy 112. Możesz zostać poproszony o przyjście z rodzicem lub opiekunem prawnym na najbliższy
        komisariat, żeby złożyć zawiadomienie osobiście.
      </p>
      <p>
        <Cap>Mail</Cap> Możesz też zgłosić przestępstwo mailem na adres mailowy komendy (
        <LinkButton link='https://www.gov.pl/web/kgpsp/komendy-wojewodzkie'>
          Strony internetowe komend wojewódzkich
        </LinkButton>
        ). Poproś o wsparcie rodziców lub opiekunów prawnych. Twoje zgłoszenie zostanie przyjęte i będzie rozpatrzone.
        Nie zawsze otrzymasz odpowiedź z komendy.
      </p>
      <p>
        Napisz na przykład:
        <ul>
          <li> Natrafiłem w internecie na film, w którym mężczyzna bije dziecko (link). Proszę o zajęcia się tym </li>
          <li> Kolega napisał, że myśli o samobójstwie (link, ewentualnie dane kolegi i adres)</li>
          <li> Nieznajoma osoba grozi mi śmiercią w komentarzu pod moim postem (link)</li>
        </ul>
      </p>
      <p>
        <Cap>osobiście</Cap> Możesz też zgłosić się z rodzicem lub opiekunem prawnym do najbliższej jednostki policji.
        Wtedy dostaniesz pisemne potwierdzenie zawiadomienia o popełnieniu przestępstwa. W przypadku takiego rozwiązania
        musisz liczyć się z koniecznością czekania w kolejce.
      </p>
    </>
  );
}
