import { QuestionDto, QuestionOptionDto } from '../models/question';

export class QuestionsEnum {
  static q1 = 1;
  static q2 = 2;
  static q3 = 3;
  static q4 = 4;
}

export enum Options {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
}

export const getQuestionsData = (questionNum: number): QuestionDto => {
  const question = questionsData.find((p) => p.num === questionNum) as QuestionDto;
  if (!question) {
    throw new Error('Not found question');
  }
  return question;
};

export const getQuestionOptionData = (questionNum: number, option: string): QuestionOptionDto => {
  const question = getQuestionsData(questionNum);
  if (question && question.options) {
    const foundOpt = question.options.find((p) => p.key === option);
    if (foundOpt) {
      return foundOpt;
    }
  }
  throw new Error('Not found question option');
};

export const questionsData: QuestionDto[] = [
  {
    num: QuestionsEnum.q1,
    text: 'Na jakie treści reagujesz?',
    options: [
      {
        key: Options.A,
        text: 'Mowa nienawiści i hejt',
        desc:
          'Mowa nienawiści to obraźliwe, ośmieszające i poniżające komentarze o Tobie, innej osobie lub grupie osób. Jest przestępstwem zawsze wtedy, kiedy zachęca do przemocy przeciwko albo obraża określoną grupę osób lub członków danej grupy, ze względu na religię, kolor skóry, pochodzenie czy narodowość.',
      },
      {
        key: Options.B,
        text: 'Groźby, straszenie, szantażowanie (cyberprzemoc)',
        desc:
          'Cyberprzemoc to przemoc w sieci. Może polegać m.in. na wyzywaniu, nękaniu, straszeniu, szantażowaniu rozsyłaniu kompromitujących zdjęć, informacji, podszywaniu się pod kogoś wbrew jego woli albo, w przypadku dzieci i nastolatków, wykluczaniu z grupy rówieśniczej. Duża część tego typu sytuacji łamie prawo, np. nękanie, groźby, naruszenie wizerunku, włamanie na konto w serwisie internetowym.',
      },
      {
        key: Options.C,
        text: 'Treści wulgarne lub przemocowe (patotreści)',
        desc:
          'Nieprzyzwoite lub obsceniczne filmy, grafiki, zdjęcia lub utwory muzyczne, w których nadawca lub grupa nadawców prezentują zachowania demoralizujące, sprzeczne z normami społecznymi, takie jak np.: przemoc fizyczna, psychiczna, seksualna, wulgarny język, libacje alkoholowe, poniżanie, zażywanie narkotyków.',
      },
      {
        key: Options.D,
        text: 'Nielegalna pornografia',
        desc:
          'Pornografia to zdjęcia, filmy, animacje i rysunki wytworzone w celu wywołania pobudzenia seksualnego, które przedstawiają w sposób bezpośredni akty seksualne. Pornografia jest nielegalna, kiedy narzucana jest dzieciom lub prezentowana jest w sieci bez wyraźnego ostrzeżenia o ograniczeniu wiekowym 18+. Przestępstwem jest również prezentowanie w sieci materiałów pornograficznych z udziałem dziecka poniżej 18 roku życia oraz treści, które zawierają przemoc i czynności seksualne przy wykorzystaniu zwierząt.',
      },
    ],
  },
  {
    num: QuestionsEnum.q2,
    text: 'Czy uważasz, że to przestępstwo?',
    info:
      'Dlaczego o to pytamy? Internet jest miejscem publicznym, tak jak ulica czy park. Zachowanie w internecie może być przestępstwem. Warto je wtedy zgłosić na policję lub do prokuratury',
    options: [
      {
        key: Options.A,
        text: 'Tak',
        desc:
          'Jeśli coś jest przestępstwem możesz to zgłosić na policję lub do prokuratury. Nawet jeżeli wybierzesz niewłaściwy organ ściągania, ma on obowiązek przekazać sprawę do właściwej instytucji. Sam zdecydujesz czy zgłosisz podejrzenie popełnienia przestępstwa, wskazujemy jednak argumenty, które mogą być pomocne przy podejmowaniu decyzji. ',
      },
      {
        key: Options.B,
        text: 'Nie',
      },
      {
        key: Options.C,
        text: 'Nie wiem',
        desc:
          'Możesz zgłosić daną treść na policję albo do prokuratury, która oceni, czy dane zachowanie jest przestępstwem. Niezależnie od decyzji, jaką podejmiesz, podpowiemy Ci też inne rozwiązania.',
      },
    ],
  },
  {
    num: QuestionsEnum.q3,
    text: 'Gdzie znalazłeś szkodliwe treści?',
    options: [
      {
        key: Options.A,
        text: 'Facebook',
      },
      {
        key: Options.B,
        text: 'YouTube',
      },
      {
        key: Options.C,
        text: 'Twitter',
      },
      // {
      //   key: Options.D,
      //   text: 'Wykop',
      // },
      {
        key: Options.E,
        text: 'Instagram',
      },
      {
        key: Options.F,
        text: 'Snapchat',
      },
      {
        key: Options.G,
        text: 'Tiktok',
      },
      {
        key: Options.H,
        text: 'Inne',
      },
    ],
  },
  {
    num: QuestionsEnum.q4,
    text: 'Ile masz lat?',
    info:
      'Dlaczego o to pytamy? Niektóre sposoby reagowania na szkodliwe treści będą różnić się w zależności od Twojego wieku. Jeśli nie masz 18 lat, pokażemy Ci jak zacząć rozmowę z zaufanym dorosłym i wskażemy telefony zaufania dla dzieci. Jeśli jesteś dorosłym, zasugerujemy jak porozmawiać z dzieckiem.',
    options: [
      {
        key: Options.A,
        text: 'Poniżej 13 lat',
      },
      {
        key: Options.B,
        text: '13-18 lat',
      },
      {
        key: Options.C,
        text: 'Powyżej 18 lat',
      },
    ],
  },
];
