import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Regulations = () => {
  return (
    <>
      <Row>
        <Col xs={12}>Regulations</Col>
      </Row>

      <hr />
    </>
  );
};

export default Regulations;
