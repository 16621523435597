import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { checkAnswers } from '../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class P implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.P;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'ZGŁOŚ DO Tiktoka';

  getContent = (answers?: AnswerDto[]) => (
    <>
      <p>
        Dowiedź się jak zgłaszać szkodliwe treści na Tiktoku{' '}
        <LinkButton link='https://support.tiktok.com/pl/safety-hc/report-a-problem' />
        {checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.A }, answers) && (
          <>
            <br></br>
            Poproś o wsparcie rodzica lub opiekuna prawnego.
          </>
        )}
      </p>
    </>
  );
}
