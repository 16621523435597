import { SurveyActions, SET_ANSWER } from './types';
import { ScrollFlowHelper } from '../data/scrollFlowHelper';

export function setAnswer(questionNum: number, optKey: string, toAdd: boolean): SurveyActions {
  ScrollFlowHelper.goToNextQuestion(questionNum);
  return {
    type: SET_ANSWER,
    answer: {
      questionNum: questionNum,
      optKey: optKey,
    },
    toAdd,
  };
}
