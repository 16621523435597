import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { checkAnswers } from '../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class M implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.M;
  isEnable = () => false;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'ZGŁOŚ DO Wykop';
  getContent = (answers?: AnswerDto[]) => (
    <>
      <p>
        Dowiedź się jak zgłaszać szkodliwe treści na Wykop Przejdź do formularza{' '}
        <LinkButton link={'https://www.wykop.pl/kontakt/'} />
        {checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.A }, answers) && (
          <>
            <br></br>
            Poproś o wsparcie rodzica lub opiekuna prawnego.
          </>
        )}
      </p>
    </>
  );
}
