import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { Row, Col } from 'react-bootstrap';

export class E implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.E;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/prokurator.svg';
  getTitle = () => 'ZGŁOŚ PRZESTĘPSTWO DO PROKURATURY';
  getContent = () => (
    <>
      <p>
        Najłatwiej jest złożyć zawiadomienie pisemne do prokuratury. Przygotowaliśmy 3 wzory w zależności od tego, czy
        masz 5, 10 czy 20 minut na opis sytuacji, którą zgłaszasz. Wyślij zawiadomienie do prokuratury właściwej ze
        względu na swoje miejsce zamieszkania.{' '}
      </p>
      <p>Wzory zawiadomień</p>
      <Row>
        <Col xs={3}>5-minutowy</Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/A formularz zawiadomienia 5 minut.pdf'}>PDF</LinkButton>
        </Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/A formularz zawiadomienia 5 minut.doc'}>DOC</LinkButton>
        </Col>
        <Col xs={5}>
          <LinkButton
            link={
              process.env.PUBLIC_URL + '/files/A objasnienia do formularza zawiadomienia o przestepstwie 5 minut.pdf'
            }>
            Instrukcja do formularza
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>10-minutowy</Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/B formularz zawiadomienia 10 minut.pdf'}>PDF</LinkButton>
        </Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/B formularz zawiadomienia 10 minut.doc'}>DOC</LinkButton>
        </Col>
        <Col xs={5}>
          <LinkButton
            link={
              process.env.PUBLIC_URL + '/files/B objasnienia do formularza zawiadomienia o przestepstwie 10 minut.pdf'
            }>
            Instrukcja do formularza
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>20-minutowy</Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/C formularz zawiadomienia 20 minut.pdf'}>PDF</LinkButton>
        </Col>
        <Col xs={1}>
          <LinkButton link={process.env.PUBLIC_URL + '/files/C formularz zawiadomienia 20 minut.doc'}>DOC</LinkButton>
        </Col>
        <Col xs={5}>
          <LinkButton
            link={
              process.env.PUBLIC_URL + '/files/C objasnienia do formularza zawiadomienia o przestepstwie 20 minut.pdf'
            }>
            Instrukcja do formularza
          </LinkButton>
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col>
          <p>
            <LinkButton link='https://www.gov.pl/web/gov/zglos-przestepstwo'>Znajdź swoją prokuraturę</LinkButton>
          </p>
        </Col>
      </Row>
    </>
  );
}
