import { InstructionsKey, AnswerDto } from '../models/question';
import { QuestionsEnum, Options, getQuestionsData, getQuestionOptionData } from './questionsData';
import ReactGA from 'react-ga';

export const processAnswer = (answers: AnswerDto[]): InstructionsKey[] => {
  if (!isAllAsnwerSelected(answers)) {
    return [];
  }

  sendAnswers(answers);
  return dictint(checkRules(answers));
};

const sendAnswers = (answers: AnswerDto[]) => {
  answers.forEach((answer) => {
    ReactGA.event({
      category: 'Set answer',
      action: getQuestionsData(answer.questionNum).text,
      label: getQuestionOptionData(answer.questionNum, answer.optKey).text,
    });
  });
};

interface Rule {
  instructionKey: InstructionsKey;
  answers: AnswerDto[][];
}

const dictint = (array: InstructionsKey[]): InstructionsKey[] => {
  return Array.from(new Set(array.map((item: InstructionsKey) => item)));
};

const checkRules = (answers: AnswerDto[]): InstructionsKey[] => {
  return rules.filter((p) => checkConditions(p, answers)).map((p) => p.instructionKey);
};

const checkConditions = (rule: Rule, answers: AnswerDto[]): boolean => {
  return rule && rule.answers && rule.answers.some((r) => r.every((s) => checkAnswers(s, answers)));
};

export const checkAnswers = (ruleAnswer: AnswerDto, answers?: AnswerDto[]): boolean => {
  if (!answers) {
    return false;
  }
  return answers.some((p) => p.questionNum === ruleAnswer.questionNum && p.optKey === ruleAnswer.optKey);
};

const isAllAsnwerSelected = (answers: AnswerDto[]): boolean => {
  const questions = [QuestionsEnum.q1, QuestionsEnum.q2, QuestionsEnum.q3, QuestionsEnum.q4];
  const foundAnswers = answers.filter((p) => questions.some((r) => r === p.questionNum));

  return questions.length === foundAnswers.length;
};

const rules: Rule[] = [
  {
    instructionKey: InstructionsKey.G,
    answers: [
      [{ questionNum: QuestionsEnum.q4, optKey: Options.A }],
      [{ questionNum: QuestionsEnum.q4, optKey: Options.B }],
    ],
  },
  {
    instructionKey: InstructionsKey.E,
    answers: [
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.C },
        { questionNum: QuestionsEnum.q2, optKey: Options.A },
      ],
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.C },
        { questionNum: QuestionsEnum.q2, optKey: Options.C },
      ],
    ],
  },
  {
    instructionKey: InstructionsKey.F,
    answers: [
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.B },
        { questionNum: QuestionsEnum.q2, optKey: Options.A },
      ],
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.B },
        { questionNum: QuestionsEnum.q2, optKey: Options.C },
      ],
    ],
  },
  {
    instructionKey: InstructionsKey.A,
    answers: [
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.C },
        { questionNum: QuestionsEnum.q2, optKey: Options.A },
      ],
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.C },
        { questionNum: QuestionsEnum.q2, optKey: Options.C },
      ],
    ],
  },
  {
    instructionKey: InstructionsKey.B,
    answers: [
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.B },
        { questionNum: QuestionsEnum.q2, optKey: Options.A },
      ],
      [
        { questionNum: QuestionsEnum.q4, optKey: Options.B },
        { questionNum: QuestionsEnum.q2, optKey: Options.C },
      ],
    ],
  },
  {
    instructionKey: InstructionsKey.C,
    answers: [[{ questionNum: QuestionsEnum.q4, optKey: Options.C }]],
  },
  {
    instructionKey: InstructionsKey.D,
    answers: [[{ questionNum: QuestionsEnum.q4, optKey: Options.C }]],
  },
  {
    instructionKey: InstructionsKey.I,
    answers: [
      [{ questionNum: QuestionsEnum.q1, optKey: Options.A }],
      [{ questionNum: QuestionsEnum.q1, optKey: Options.D }],
    ],
  },
  {
    instructionKey: InstructionsKey.J,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.A }]],
  },
  {
    instructionKey: InstructionsKey.K,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.B }]],
  },
  {
    instructionKey: InstructionsKey.L,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.C }]],
  },
  {
    instructionKey: InstructionsKey.M,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.D }]],
  },
  {
    instructionKey: InstructionsKey.N,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.E }]],
  },
  {
    instructionKey: InstructionsKey.O,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.F }]],
  },
  {
    instructionKey: InstructionsKey.P,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.G }]],
  },
  {
    instructionKey: InstructionsKey.Q,
    answers: [[{ questionNum: QuestionsEnum.q3, optKey: Options.H }]],
  },
];
