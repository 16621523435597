import React, { Component, ComponentProps } from 'react';
import ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class GoogleWrapper extends React.Component<RouteComponentProps> {
  unregister: any;

  constructor(props: RouteComponentProps) {
    super(props);
    ReactGA.initialize('UA-77525895-21');
  }

  componentDidMount() {
    this.unregister = this.props.history.listen((p: any) => {
      window.scrollTo(0, 0);
      this.logViewPage(p.pathname);
    });
    this.logViewPage(this.props.location.pathname);
  }

  private logViewPage(path: string) {
    ReactGA.pageview(path);
  }

  render() {
    return <>{this.props.children}</>;
  }

  componentWillUnmount() {
    if (this.unregister) {
      this.unregister();
    }
  }
}
export const GoogleWrapperWithRouter = withRouter(GoogleWrapper);
