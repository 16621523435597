import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LinkButton from './shared/LinkButton';
import ImgBox from './shared/ImgBox';

const About = () => {
  return (
    <>
      <Row className='textJustify'>
        <Col xs={{ span: 10, offset: 1 }}>
          <Row>
            <Col xs={12}>
              <h3 className='bold'>O NAS</h3>
              <p>
                <b>Zgłoś.to</b> to inicjatywa Biura Rzecznika Praw Obywatelskich oraz Fundacji Dajemy Dzieciom Siłę
                zrealizowana z pomocą partnerów: ITMAGINATION, Hill + Knowlton, DDB oraz Facebook.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12} className={'itemsToCenter aboutFirstPara'}>
              <p>
                Reagowanie to często dla nas wyzwanie, podczas którego możemy czuć się niekomfortowo. Większość z nas ma
                jednak wszystko, żeby zareagować: potrafimy zidentyfikować problem, nazwać go i planować odpowiednie
                działania. To wystarczające zasoby, aby podjąć działanie. Aby ułatwić proces zgłaszania, zebraliśmy w
                jednym miejscu informacje, które pomogą w reagowaniu na szkodliwe i przemocowe treści, znajdujące się w
                internecie.
              </p>
              <p>Znajdziecie tutaj: </p>
              <ul>
                <li>linki do administratorów poszczególnych platform internetowych, </li>
                <li>wskazówki i formularze odpowiednie do przygotowania zgłoszenia na policję lub do prokuratury, </li>
                <li>
                  linki do portali, gdzie można wkleić przykładowe nienawistne lub nielegalne treści – a ich
                  administratorzy podejmą dalsze działania w celu ich usunięcia,{' '}
                </li>
                <li>wskazówki dla dzieci i rodziców pomocne w rozmowach na temat szkodliwych treści.</li>
              </ul>
            </Col>
            <Col md={5} xs={12}>
              <ImgBox
                src={process.env.PUBLIC_URL + '/rpo/zglos.to.poster.3.png'}
                alt='Plakat Zgłoś.to'
                className='imgMaxWidth'
              />
            </Col>

            <Col md={5} xs={12}>
              <ImgBox
                src={process.env.PUBLIC_URL + '/rpo/zglos.to.poster.2.png'}
                alt='Plakat Zgłoś.to'
                className='imgMaxWidth'
              />
            </Col>
            <Col md={7} xs={12} className={'itemsToCenter'}>
              <p>
                Inicjatywa <b>Zgłoś.to</b> wpisuje się działania powołanego w 2018 roku przez Rzecznika Praw
                Obywatelskich Okrągłego Stołu poświęconego problemowi szkodliwych, patologicznych treści w internecie i
                ich wpływowi na dzieci i młodzież. Biorą w nim udział prawnicy, naukowcy, przedstawiciele władz
                publicznych, organizacji pozarządowych, firm technologicznych oraz youtuberzy i dziennikarze, którzy
                wypracowali liczne postulaty potrzebnych zmian i podjęcia pilnych działań. Wśród nich było pogłębienie
                wiedzy na temat problemu patotreści w internecie i konieczności zgłaszania szkodliwych treści. O
                specyfice, skali i wpływie patotreści na młodych internautów możecie przeczytać w raporcie „Patotreści w
                sieci” (
                <LinkButton link='https://www.rpo.gov.pl/sites/default/files/Raport%20z%20bada%C5%84%20nastolatk%C3%B3w%20nt.%20patotre%C5%9Bci.pdf'></LinkButton>
                ) przygotowanym przez Fundację Dajemy Dzieciom Siłę we współpracy z Rzecznikiem Praw Obywatelskich i
                Orange. Kampanią społeczną <b>Zgłoś.to</b> i towarzyszącym jej działaniom promocyjnym chcemy przekonać
                ludzi, że warto nie być biernym, że warto się angażować!
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>
                <b>
                  Internet to przestrzeń publiczna (
                  <LinkButton link='https://www.rpo.gov.pl/pl/content/sad-najwyzszy-zbada-kasacje-rpo-w-sprawie-lidera-dumy-i-nowoczesnosci'>
                    Zobacz
                  </LinkButton>
                  ).{' '}
                </b>
              </h5>
              <p>
                Mówimy stanowcze NIE! przemocy i wulgaryzmom w internecie. <b>Zgłoś.to</b>
              </p>
              <p>
                Masz pytania dotyczące akcji? <br></br>
                Skontaktuj się z nami: <LinkButton link='mailto:zglosto@fdds.pl'>zglosto@fdds.pl</LinkButton>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default About;
