import { InstructionsKey } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';

export class Q implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.Q;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/zglos_na_platformie.svg';
  getTitle = () => 'Inne';
  getContent = () => (
    <>
      <p>
        Jeśli widzisz niepożądaną treść na innym portalu pamiętaj – zawsze możesz ten fakt zgłosić do administratora lub
        moderatora portalu. Niektóre portale przygotowały specjalny formularz kontaktowy, a niektóre udostępniają adres
        mailowy do kontaktu. Poszukaj takiej informacji na stronie (najczęściej znajduje się ona na samym dole pod
        zakładką Kontakt lub Administrator) i ZGŁOŚ.TO
      </p>
    </>
  );
}
