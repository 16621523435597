import { InstructionsKey, AnswerDto } from '../../models/question';
import { InstructionComponent } from '../../models/instructions';
import React from 'react';
import LinkButton from '../../components/shared/LinkButton';
import { checkAnswers } from '../selectInstructionLogic';
import { QuestionsEnum, Options } from '../questionsData';

export class D implements InstructionComponent {
  getInstructionKey = () => InstructionsKey.D;
  isEnable = () => true;
  getIconSrc = () => '/rpo/svg/Porozmawiaj.svg';
  getTitle = () => 'Porozmawiaj z ekspertem';
  getContent = (answers?: AnswerDto[]) => {
    if (checkAnswers({ questionNum: QuestionsEnum.q4, optKey: Options.C }, answers)) {
      return (
        <>
          <p>
            Jeśli masz wątpliwości dotyczące sytuacji w jakiej znalazło się Twoje dziecko lub dziecko, które jest pod
            Twoją opieką - możesz zasięgnąć porady specjalisty dzwoniąc pod bezpłatny numer Telefonu dla Rodziców i
            Profesjonalistów <LinkButton link='tel:800100100'>800 100 100</LinkButton>. Linia wsparcia jest czynna od
            poniedziałku do piątku, w godzinach 12.00-15.00.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            Znajdź własciwy numer telefonu w zależności od tematu pod którym możesz porozmawiac o swoim problemie:
            <LinkButton link='https://116111.pl/polecamy/telefony-zaufania-,aid,8'>Wykaz telefonów zaufania</LinkButton>
          </p>
        </>
      );
    }
  };
}
