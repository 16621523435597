import React from 'react';
import ReactGA from 'react-ga';

interface Props {
  link?: string;
  title?: string;
}

const LinkButton: React.FC<Props> = (props) => {
  let content = <>LINK</>;
  let link = '#';

  if (props.children) {
    content = <>{props.children}</>;
  } else if (props.title) {
    content = <>{props.title}</>;
  }

  if (props.link) {
    link = props.link;
  }

  const click = () => {
    ReactGA.event({
      category: 'Click link',
      action: link,
    });
  };

  return (
    <a href={link} target='_blank' rel='noopener noreferrer' onClick={click} className='link'>
      {content}
    </a>
  );
};

export default LinkButton;
