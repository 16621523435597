import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Banner112 from './Banner112';

const Description = () => {
  return (
    <Row>
      <Col>
        <Row className='headerFont reverseOnMobile'>
          <Col md={8} xs={12}>
            <p className='firstDesc'>
              Nie bądźmy obojętni.<br></br>
              Widzisz szkodliwe treści w internecie? <br></br>
              <span className='red'>Zgłoś to!</span>
              <br />
            </p>
            {/* <p>
          Wszystko, co powoduje w odbiorcy zaniepokojenie, strach, oburzenie może być szkodliwą treścią. Internet
          powinien być przyjaznym miejscem dla wszystkich. Ta strona ma pomóc zareagować na treści, które są szkoldiwe -
          dla Ciebie lub dla dzieci, kóre mogą to zobaczyć.
        </p> */}
          </Col>
          <Col md={4} xs={12} className='alignTop'>
            <Banner112 />
          </Col>
        </Row>
        <Row className='headerFont'>
          <Col>
            <p className='bold'>
              Odpowiedz na wszystkie 4 pytania. Na podstawie Twoich odpowiedzi wskażemy Ci najlepszy sposób reagowania
              na szkodliwe treści.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Description;
